
require("./../node_modules/@module-federation/nextjs-mf/src/include-defaults");
import { ChevronRightIcon } from '@heroicons/react/20/solid';
import { DefaultSeo, NextSeo } from 'next-seo';
import Image from 'next/image';
import Link from 'next/link';
import { links } from '../components/config/404';

const NotFound = () => {
  return (
    <>
      <NextSeo
        title="Page not found | 247 Radio Carz"
        description="Page not found."
        openGraph={{
          url: 'https://www.247radiocarz.co.uk/404',
        }}
        canonical="https://www.247radiocarz.co.uk/"
      />
      <div className="bg-white">
        <main className="w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="max-w-xl py-16 mx-auto sm:py-24">
            <div className="text-center">
              <p className="text-base font-semibold text-indigo-600">404</p>
              <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                This page does not exist.
              </h1>
              <p className="mt-2 text-lg text-gray-500">
                The page you are looking for could not be found.
              </p>
            </div>
            <div className="mt-12">
              <h2 className="text-base font-semibold text-gray-500">
                Popular pages
              </h2>
              <ul
                role="list"
                className="mt-4 border-t border-b border-gray-200 divide-y divide-gray-200"
              >
                {links.map((link, linkIdx) => (
                  <li
                    key={linkIdx}
                    className="relative flex items-start py-6 space-x-4"
                  >
                    <div className="relative flex-shrink-0">
                      <span className="flex items-center justify-center w-[50px] h-[50px] rounded-lg bg-indigo-50">
                        <Image
                          aria-hidden="true"
                          layout="fixed"
                          src={link.imgSrc}
                          height={link.height}
                          width={link.width}
                          alt={link.alt}
                        />
                      </span>
                    </div>
                    <div className="flex-1 min-w-0">
                      <h3 className="text-base font-medium text-gray-900">
                        <span className="rounded-sm focus-within:ring-2 focus-within:ring-indigo-500 focus-within:ring-offset-2">
                          <a href={link.link} className="focus:outline-none">
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            {link.title}
                          </a>
                        </span>
                      </h3>
                      <p className="text-sm text-gray-500">
                        {link.description}
                      </p>
                    </div>
                    <div className="self-center flex-shrink-0">
                      <ChevronRightIcon
                        className="w-5 h-5 text-gray-400"
                        aria-hidden="true"
                      />
                    </div>
                  </li>
                ))}
              </ul>

              <div className="text-base font-medium text-indigo-600 hover:text-indigo-500">
                <Link href="/"> Or go back home</Link>
                <span aria-hidden="true"> &rarr;</span>
              </div>
            </div>
          </div>
        </main>
      </div>
    </>
  );
};
export default NotFound;
