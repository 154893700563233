export const links = [
  {
    title: 'Book Online',
    description:
      'Royal Cars your local taxi firm for over 30 years offering the quickest most reliable taxi service in Teesside, We cover the whole of Stockton, Middlesbrough, Billingham, Redcar and East Cleveland.',
    imgSrc: '/assets/images/book_online.png',
    width: '40',
    height: '40',
    alt: 'Book Online',
    link: '/book-online',
  },
  {
    title: 'Flexible Business Accounts',
    description:
      'Travel services for both business and personal needs throughout Lancashire.',
    imgSrc: '/assets/images/online_business_account.png',
    width: '40',
    height: '40',
    alt: 'Flexible Business Accounts',
    link: '/accounts-customer',
  },
  {
    title: 'Become A Driver',
    description:
      'Become a driver and earn great wages in an industry-leading environment with our flexible shift options, you can be your own boss while earning the best wages around.',
    imgSrc: '/assets/images/driver-bk.png',
    width: '40',
    height: '40',
    alt: 'Become A Driver',
    link: '/become_a_driver',
  },
];
